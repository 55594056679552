<template>
    <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
        <template #button-content>
            <span class="ml-50 text-body">{{ currentLanguage.name }}</span>
        </template>
        <b-dropdown-item v-for="language in languages" :key="language.code" @click="switchLanguage(language.code)">
            <span class="ml-50">{{ language.name }}</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { mapState } from 'vuex'
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
    },
    computed: {
        ...mapState({
            languages: (state) => state.global.languages,
        }),
        currentLanguage() {
            return this.languages.find((language) => language.code === this.$i18n.locale)
        },
    },
    methods: {
        switchLanguage(code) {
            this.$i18n.locale = code
            this.$store.commit('global/UPDATE_LANGUAGE', code)
        },
    },
}
</script>

<style></style>
