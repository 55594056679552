export default [
    {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        title: 'Welcome Page',
        route: 'welcome',
        icon: 'ThumbsUpIcon',
    },
    {
        title: 'Survey Page',
        route: 'survey',
        icon: 'FileIcon',
    },
    {
        title: 'Templates Market',
        route: 'market',
        icon: 'LayoutIcon',
    },
]
