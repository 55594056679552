<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21" />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <dark-Toggler class="d-none d-lg-block" />
            <Locale class="d-none d-lg-block" />
        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">John Doe</p>
                        <span class="user-status">Admin</span>
                    </div>
                    <b-avatar
                        size="40"
                        variant="light-primary"
                        badge
                        :src="require('@/assets/images/avatars/13-small.png')"
                        class="badge-minimal"
                        badge-variant="success"
                    />
                </template>

                <b-dropdown-item link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="UserIcon" class="mr-50" />
                    <span>Profile</span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="MailIcon" class="mr-50" />
                    <span>Inbox</span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
                    <span>Task</span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
                    <span>Chat</span>
                </b-dropdown-item>

                <b-dropdown-divider />

                <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
                    <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                    <span>Logout</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import auth from '@/api/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'

export default {
    components: {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,

        // Navbar Components
        DarkToggler,
        Locale,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
    methods: {
        async logout() {
            await auth.logout().finally(() => {
                this.$router.push('/login')
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t('messages.logout_success'),
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                })
            })
        },
    },
}
</script>
